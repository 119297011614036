import mapVerify from '@/utils/formValidate'

// 获取更多查询字段列表
export const getMoreQueryFormData = (that, formModel) => {
  const moreQueryData = [
    // 区域编码
    {
      name: 'areaCode',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.areaCode')
    },
    // 区域名称
    {
      name: 'areaName',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.areaName')
    },
    // 所属工厂
    {
      name: 'factoryId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingFactory'),
      filterable: true,
      data: that.factoryList,
      mchange: that.handleFactoryChange
    },
    // 所属车间
    {
      name: 'workshopId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingWorksop'),
      filterable: true,
      data: that.workshopList
    }
  ]
  moreQueryData.forEach(item => {
    if (!item.mchange) {
      item.mchange = that.onFormChange
    }
  })
  return moreQueryData
}
// 查询列表列表
export const getSearchTableItem = (that) => {
  const searchTableItem = [
    // 区域编码
    {
      prop: 'areaCode',
      label: that.$t('lang.gles.base.areaCode'),
      isShow: true,
      sortable: true
    },
    // 区域名称
    {
      prop: 'areaName',
      label: that.$t('lang.gles.base.areaName'),
      isShow: true
    },
    // 所属工厂
    {
      prop: 'factoryName',
      label: that.$t('lang.gles.base.belongingFactory'),
      isShow: true
    },
    // 所属车间
    {
      prop: 'workshopName',
      label: `${that.$t('lang.gles.base.belongingWorksop')}`,
      isShow: true
    },
    // 区域描述
    {
      prop: 'description',
      label: `${that.$t('lang.gles.base.areaDesc')}`,
      isShow: true,
      showOverflowTooltip: true
    }
  ]
  searchTableItem.forEach((item) => (item.showOverflowTooltip = true))

  return searchTableItem
}

// 编辑弹框 基础信息
export const getEditBaseFormData = (that, row) => {
  const formData = [
    // 区域编码
    {
      name: 'areaCode',
      value: row.areaCode || '',
      span: 24,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.areaCode'),
      rules: mapVerify(['required', 'inputCode']),
      appendSlotName: 'inputCodeTipIcon',
      class: 'tip-icon'
    },
    // 区域名称
    {
      name: 'areaName',
      value: row.areaName || '',
      span: 24,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.areaName'),
      placeholder: that.$t('lang.gles.common.inputLen200'),
      rules: mapVerify(['required', 'inputLen200'])
    },
    // 所属工厂
    {
      name: 'factoryId',
      value: row.factoryId || '',
      span: 24,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingFactory'),
      filterable: true,
      data: that.factoryList,
      rules: mapVerify(['required']),
      mchange: that.handleFactoryChange
    },
    // 所属车间
    {
      name: 'workshopId',
      value: row.workshopId || '',
      span: 24,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.belongingWorksop'),
      clearable: true,
      filterable: true,
      data: that.workshopList,
      rules: mapVerify(['required'])
    },
    {
      name: 'description',
      value: row.description || '',
      span: 24,
      component: 'elInput',
      type: 'textarea',
      rows: 6,
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.areaDesc'),
      rules: mapVerify(['inputLen500'])
    }
  ]
  formData.forEach((item) => {
    if (!item.mchange) {
      item.mchange = that.onFormChange
    }
  })
  return formData
}
