<template>
  <div class="basic-data-list">
    <query-view
      ref="myQueryView"
      name="codeOrName"
      :value.sync="baseFormModel.codeOrName"
      :placeholder="$t('lang.gles.common.pleaseInputCodeOrName')"
      :query-list="moreQueryData"
      @baseSearch="handleBaseSearch"
      @moreSearch="handleMoreSearch"
    />
    <el-card class="table-card">
      <div class="operator-wrapper">
        <el-button type="primary" icon="el-icon-plus" @click="updateCom">
          {{ $t('lang.gles.common.addNew') }}
        </el-button>
        <!-- 列设置 -->
        <column-config :list.sync="columnConfigList" />
      </div>
      <m-table
        :table-item="tableItem"
        :table-data="tableData"
        :page-data="pageData"
        :extend-config="tableExtendConfig"
        @editItem="editItem"
        @delItem="delItem"
        @pageChange="pageChange"
      />
    </el-card>
  </div>
</template>
<script>
import ColumnConfig from '@/components/columnConfig'
import QueryView from '@/components/moreQuery/queryView.vue'
import MTable from '@/libs_sz/components/MTable/MTable'
import { getMoreQueryFormData, getSearchTableItem } from '../data'
import * as types from '@/store/mutationTypes'
import listMixins from '@/mixins/listMixins'
import commonMixins from '@/mixins/commonMixins'

export default {
  name: 'AreaList',
  components: {
    ColumnConfig,
    QueryView,
    MTable
  },
  mixins: [listMixins, commonMixins],
  data() {
    return {
      tableData: [],
      formModel: {},
      baseFormModel: {}
    }
  },
  computed: {
    moreQueryData() {
      return getMoreQueryFormData(this, { ...this.formModel })
    },
    /**
     * 默认的表格列表项
     */
    defaultTableItem() {
      return getSearchTableItem(this)
    },
    /**
     * 渲染的表格列表项  列设置
     */
    tableItem() {
      let list = this.defaultTableItem
      if (this?.columnConfigList?.length) {
        list = this.defaultTableItem.filter(
          (item, i) =>
            item.name === this.columnConfigList[i]?.name &&
            this.columnConfigList[i]?.isShow
        )
      }
      return list
    }
  },
  async created() {
    this.doSearch(this.baseFormModel)
    this.initSelectList()
  },
  methods: {
    /**
     * 初始化下拉选项
     */
    initSelectList() {
      this.getFactoryList()
      this.getWorkshopList()
    },
    /**
     * 所属工厂 change
     * 工厂-车间
     */
    handleFactoryChange(val, formItem, changeFormValue, formModel) {
      this.$set(this.formModel, formItem.name, val)
      this.$set(this.formModel, 'workshopId', null)
      this.getWorkshopList({ factoryId: this.formModel.factoryId })
    },
    // 新增
    updateCom({ row }) {
      this.$emit('updateCom', {
        currentCom: 'AreaAdd',
        mode: 'add',
        row
      })
    },
    /**
     * 基础查询
     */
    handleBaseSearch(data) {
      this.doSearch({ ...data })
    },
    /**
     * 更多查询
     */
    handleMoreSearch(data) {
      // this.formModel = { ...data }
      this.doSearch({ ...data }, 'getAreaList')
    },
    /**
     * 查询
     */
    async doSearch(params, api = 'getAreaListByCodeOrName') {
      try {
        const { data, code } = await this.$httpService(
          this.$apiStore.base(api, this.pageData),
          params
        )
        this.$store.commit(types.IS_LOADING, false)
        if (code) return
        const {
          recordList = [],
          currentPage,
          pageSize,
          totalRecordCount: recordCount
        } = data || {}
        this.pageData = { currentPage, pageSize, recordCount }
        this.tableData = recordList || []
      } catch (error) {
        this.$store.commit(types.IS_LOADING, false)
      }
    },
    /**
     * 编辑
     */
    editItem({ row }) {
      console.log(row)
      this.$emit('updateCom', {
        currentCom: 'AreaAdd',
        mode: 'edit',
        row
      })
    },
    /**
     * 删除
     */
    delItem({ row }) {
      this.$httpService(this.$apiStore.base('deleteArea'), {
        id: row.id,
        areaId: row.areaId
      }).then(({ code }) => {
        if (code) return
        this.$message.success(this.$t('lang.gles.common.deleteSuccessfully'))
        if (this.tableData?.length < 2) {
          this.pageData.currentPage = 1
        }
        this.doSearch(this.baseFormModel)
      })
    }
  }
}
</script>
